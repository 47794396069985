<template>
    
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation title="Login" icon="" />

    <div class="loginContainer">
        <div class="row">

            <!-- Left column - Login -->
            <div class="col-12 col-lg-8 d-grid">
                <!-- <div class="border shadow-sm p-3 loginBox" style="min-height: 218px"> -->
                <div class="border shadow-sm loginBox">
                    <div class="row p-0">
                        <div class="col-12 mb-4">
                            Welcome to SignOn, you may sign document online with verified KYC identity via our simple, fast and secure app.
                        </div>
                    </div>

                    <div v-if="stage === 1" class="row p-0">
                        <div class="col-12 pb-3">
                            <input v-model="username" type="text" class="form-control" placeholder="Username">
                        </div>

                        <div class="col-12 text-end">
                            <button class="btn btn-secondary" @click="nextStage">Login</button>
                        </div>

                        <div class="col-12 text-center pt-5">OR, login via</div>
                        
                        <div class="col-12"><hr class="mt-1 mb-5" /></div>

                        <div class="col text-center">
                            <span class="isLink" @click="loginGoogle">
                                <Popper class="popperDark" arrow hover content="Google">
                                    <fa :icon="['fab', 'google']" style="font-size: 28px" />
                                </Popper>
                            </span>
                        </div>
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Facebook">
                                <fa :icon="['fab', 'facebook']" style="font-size: 28px" />
                            </Popper>
                            </div>
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Yahoo">
                                <fa :icon="['fab', 'yahoo']" style="font-size: 28px" />
                            </Popper>
                        </div>
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Microsoft">
                                <fa :icon="['fab', 'microsoft']" style="font-size: 28px" />
                            </Popper>
                        </div>
              
                    </div>

                    <div v-if="stage === 2" class="row">
                        <div class="col-12 pb-3">
                            <input v-model="username" type="text" class="form-control mb-2" readonly>
                            <input v-model="password" type="password" class="form-control" placeholder="Password">
                        </div>

                        <div class="col-12 text-end">
                            <button class="btn btn-secondary me-2" @click="reset">Reset</button>
                            <button class="btn btn-secondary" @click="login">Login</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- Right column -->
            <div class="col-12 col-lg-4">
                <div class="border shadow-sm loginBoxSm">
                    <div>Forget your password? </div>
                    <router-link :to="{ name: 'ForgotPassword', query: route.query}">
                        <div class="greyLink"><u>Reset my password</u></div>    
                    </router-link>
                </div>

                <div class="border shadow-sm loginBoxSm">
                    <div>Not an user yet?</div>
                    <router-link :to="{ name: 'SignUp', query: route.query}">
                        <div class="greyLink"><u>Get a FREE account now</u></div>
                    </router-link>
                </div>

                <div class="border shadow-sm loginBoxSm">
                    <div>Still undecided what to do?</div>
                    <div class="isLink"><u>View our demo now</u></div>
                </div>
            </div>

        </div>
    </div>
    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from '@/functions/axios-instance'
import { useStore } from '@/stores/store'
import cookie from '@/functions/cookie'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import auth from '@/functions/auth'

export default {
    name: 'Login',
    components: { TopNavigation, Alert, Popper },
    inject: ['auth'],
    setup () {
        const alert = ref([])

        const stage = ref(1)
        const username = ref(null)
        const refId = ref(null)
        const password = ref(null)
        const urlSignUp = ref('/auth/signup')
        const displayName = ref('')
        const avatar = ref('')
        const userId = ref('')
        const lastLogin = ref('')

        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getUserId, updUserId, getDisplayName, updDisplayName, getAvatar, updAvatar, getUserLastLogin, updUserLastLogin } = useStore()

        const func = funcs

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const s = ref(null)
        const y = ref(null)
        
        const nextStage = async () => {

            if (username.value === null || username.value === '') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Please enter username.'
                    })

            } else {

                const p = { "username": username.value }

                axios.post('/auth/signinreq', p, config
                ).then((res) => {
                    
                    if (res.data.status === 1) {
                        stage.value = 2
                        refId.value = res.data.data.refId

                    } else {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    }
                })
                .catch((error) => {

                    console.info('login ERR', JSON.stringify(error))
                     if (error.status === 'possible_attack') {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: 'You have exceeded the maximum login attempts. (' + error.code + ')'
                        })

                    } else {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    }
                })
            }
        }

        const login = async () => {

            const p = {
                "refId" : refId.value,
                "password": password.value
            }

            axios.post('/auth/signin', p, config
            ).then((res) => {
                console.info('login', JSON.stringify(res.data))

                if (res.data.status === 1) {
                    console.info('LOGIN SUCCESS', JSON.stringify(res.data.data))

                    localStorage.setItem('userId', res.data.data.userId)
                    localStorage.setItem('sessionId', res.data.data.sessionId)
                    localStorage.setItem('role', res.data.data.role)
                    localStorage.setItem('doc', {})

                    displayName.value = computed({
                        get: () => store.getDisplayName.value,
                        set: store.updDisplayName(res.data.data.displayName),
                    })
                    displayName.value = store.getDisplayName.value 

                    avatar.value = computed({
                        get: () => store.getAvatar.value,
                        set: store.updAvatar(res.data.data.avatar),
                    })
                    avatar.value = store.getAvatar.value 

                    userId.value = computed({
                        get: () => store.getUserId.value,
                        set: store.updUserId(res.data.data.userId),
                    })
                    userId.value = store.getUserId.value 

                    lastLogin.value = computed({
                        get: () => store.getUserLastLogin.value,
                        set: store.updUserLastLogin(res.data.data.dateLastLogin),
                    })
                    lastLogin.value = store.getUserLastLogin.value

                    let redirectPath = '/dashboard'
                    if (route.query.redirect) {
                        
                        route.query.fr = res.data.data.displayName
                        if (route.query.redirect === 'ViewProj') {
                            redirectPath = '/p/' + route.query.p
                        } else if (route.query.redirect === 'ViewProjDoc') {
                            redirectPath = '/p/' + route.query.p + '/' + route.query.d
                        } else if (route.query.redirect === 'ViewDocInfo') {
                            redirectPath = '/d/' + route.query.d

                        } else if (route.query.redirect === 'UploadDocNew') {
                            redirectPath = '/uploadDoc'
                        } else if (route.query.redirect === 'UploadDoc') {
                            redirectPath = '/uploadDoc/' + route.query.p
                        } else if (route.query.redirect === 'ProjectOverview') {
                            redirectPath = '/project/' + route.query.p
                        } else if (route.query.redirect === 'ProjectList') {
                            redirectPath = '/project'
                        } else if (route.query.redirect === 'VerifyDoc') {
                            redirectPath = '/doc/verify'
                        } else if (route.query.redirect === 'PdfView') {
                            redirectPath = '/pdf/view/' + route.query.p + '/' + route.query.d
                        } else if (route.query.redirect === 'Signatory') {
                            redirectPath = '/signatory'
                        } else if (route.query.redirect === 'PdfDetail') {
                            redirectPath = '/pdf/detail'
                        } else if (route.query.redirect === 'PdfDetailSignee') {
                            redirectPath = '/pdf/detailSignee'
                        } else if (route.query.redirect === 'PdfDetailSignee2') {
                            redirectPath = '/pdf/detailSignee2'
                        } else if (route.query.redirect === 'AddSignee') {
                            redirectPath = '/addSignee'
                        } else if (route.query.redirect === 'ReviewInvite') {
                            redirectPath = '/reviewInvite'
                        } else if (route.query.redirect === 'ManageAccount') {
                            redirectPath = '/auth/manageaccount'
                        } else {
                            redirectPath = '/dashboard'
                        }
                    }

                    console.info('redirect to', redirectPath)
                    

                    setTimeout(() => {
                        /* router.push(redirectPath)
                        .then(() => {
                            // refresh the page so that can capture sessionId in store
                            router.go() 
                        }) */
                        if (redirectPath === '/uploadDoc') {
                            router.push({ name: 'UploadDocNew', query: { l: res.data.data.dateLastLogin, a: res.data.data.dateLastActive, fl: res.data.data.isFirstTimeLogin } })

                        } else {
                            // router.push({ name: route.query.redirect, query: { l: '2022-07-22T13:18:59.555Z', a: '2022-07-22T13:18:59.555Z' } })
                            router.push({ path: redirectPath, query: { l: res.data.data.dateLastLogin, a: res.data.data.dateLastActive, fl: res.data.data.isFirstTimeLogin } })
                        }
                    }, 2000)

                    
                } 
            })
            .catch((error) => {
                console.info('login Err', JSON.stringify(error))

                if (error.status === 'ip_not_match' || error.status === 'user_not_found') {

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Error found when logging in, please contact support at support@signon.my. (' + error.code + ')'
                    })

                } else if (error.status === 'possible_attack') {

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'You have exceeded the maximum login attempts. (' + error.code + ')'
                    })

                } else if (error.status === 'refId_expired') {

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Timeout. Please try again. (' + error.code + ')'
                    })

                } else if (error.status === 'invalid_password') {

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Password incorrect. (' + error.code + ')'
                    })

                } else {

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                }
            })
        }

        const loginGoogle = () => {

            axios.get('/auth/signin/oauth/google', 
            ).then((res) => {
                
                console.info('google', JOSN.stringify(res))
                if (res.data.status === 1) {
                    s.value = res.s
                    y.value = res.y

                }
            })
            .catch((error) => {

                console.info('login ERR', JSON.stringify(error))

                if (error.status === 'invalid_open_id') {
                     alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'User is registered in SignOn. Please use system login instead of login via Google, Facebook, Yahoo or Microsoft. (' + error.code + ')'
                    })

                } else if (error.status === 'google_auth_failed') {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Failed to login via Google. (' + error.code + ')'
                    })

                } else if (error.status === 'facebook_auth_failed') {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Failed to login via Facebook. (' + error.code + ')'
                    })

                } else {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                }
            })
        }

        const reset = () => {
            stage.value = 1
            username.value = null
            refId.value = null
            password.value = null
        }

        const closeAlert = (index) => {
            // alert.value = null
            alert.value.splice(index, 1)
            console.info('index', index)
        }

        onMounted(async () => {
            // console.info('. . . LOGIN onMounted')
            auth.clearStorage()

            if (route.query.e === 'timeout') {
                alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Session timeout. Please login again.'
                    })
            }

            if (route.query.m === '1') {
                // Manually logout
                alert.value.push({
                        class: "info",
                        title: "INFO",
                        message: 'You have been logged out.'
                    })
            }
        })

        return { 
            alert, route, stage, username, refId, password, urlSignUp, 
            nextStage, login, reset, closeAlert, func, 
            loginGoogle, s, y, 
            /* displayName, updDisplayName, sessionId */ 
        }
    }
}
</script>

<style>
.loginBox {
  border-radius: 10px;
  /* padding: 35px 40px; */
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.loginBoxSm {
  border-radius: 10px;
  /* padding: 35px 40px; */
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--auth-box-bg);
}

.loginContainer {
  width: 53%; 
  margin: 105px auto 20px auto;
  border-radius: 10px;
  
}

@media (max-width: 575.98px) {
    .loginBox {
      border-radius: 10px;
    }

    .loginBoxSm {
      border-radius: 10px;
      background-color: var(--auth-box-bg);
    }

    .loginContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }
}
</style>